<template>
    <div style="height: calc(100vh - 100px)">
        <iframe
            :src="src"
            style="width: 100%; height: 100%; border: none"
            frameBorder="0"
        ></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            package_id: '',
            quantity: 1,
            voucher_code: '',
            extra_data: '',
            redirect: `/payment/transfer?package_id=&quantity=3&voucher_code=&trans_type=1`,
            src: `https://banhang.atosa.asia/authenticate/handle?token=${localStorage.getItem(
                'token'
            )}&redirect=`
        }
    },

    computed: {
        s__channel() {
            return this.$store.getters.app.s__channel
        }
    },

    beforeMount() {
        this.package_id = this.$route.query.package_id
        this.quantity = this.$route.query.quantity
        this.voucher_code = this.$route.query.voucher_code

        this.redirect += `&channel=${this.s__channel}`

        if (this.$route.query.extra) {
            this.redirect += `&extra=${this.$route.query.extra}`
        }
        if (this.$route.query.amount_options) {
            this.redirect += `&amount_options=${this.$route.query.amount_options}`
        }
        if (this.$route.query.amount) {
            this.redirect += `&amount=${this.$route.query.amount}`
        }
        if (this.$route.query.amount_allow_input) {
            this.redirect += `&amount_allow_input=${this.$route.query.amount_allow_input}`
        }
        if (this.$route.query.amount_limit) {
            this.redirect += `&amount_limit=${this.$route.query.amount_limit}`
        }
        if (this.$route.query.tkcn) {
            this.redirect += `&tkcn=${this.$route.query.tkcn}`
        }
        if (this.$route.query.lang) {
            this.redirect += `&lang=${this.$route.query.lang}`
        }

        this.src += encodeURIComponent(this.redirect)

        if (this.$route.query.user_info_inject) {
            this.src += encodeURIComponent(
                `&user_info_inject=${this.$route.query.user_info_inject}`
            )
        }

        window.addEventListener(
            'message',
            (msg) => {
                if (['payment_success'].includes(msg.data)) {
                    window.opener.postMessage('payment_success', '*')
                }
            },
            false
        )
    },

    mounted() {
        window.addEventListener(
            'message',
            (msg) => {
                if (['payment_success'].includes(msg.data)) {
                    setTimeout(() => {
                        if (window.opener) {
                            window.opener.callback(this.$route)
                            window.close()
                        }
                    }, 2000)
                }
            },
            false
        )
    }
}
</script>
